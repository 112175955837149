<template>
  <v-autocomplete
    v-model="model"
    :label="label"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :disabled="disabled"
    :error-messages="errorsMessages"
    :rules="rules"
    outlined
  ></v-autocomplete>
</template>
<script>
/**
 * Base auto complete
 * @component
 */
export default {
  props: {
    /**
     *Items that will appear
     */
    items: {
      type: Array,
      default: () => []
    },
    /**
     * @model
     */
    value: {
      type: [String, Number],
      default: ''
    },
    /**
     * Label
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Property that will be used for the item's text
     */
    itemText: {
      type: String,
      default: ''
    },
    /**
     * Property that will be used for the item's value
     */
    itemValue: {
      type: String,
      default: ''
    },
    /**
     * Disables the component
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Input error messages
     */
    errorsMessages: {
      type: String,
      default: ''
    },
    /**
     * Rules of autocomplete
     * @values [] | [() => !!name || 'This field is required']
     */
    rules: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      model: this.value
    }
  },
  watch: {
    model(val) {
      /**
       * evento que realiza a atualização da model
       * @event input
       * @property {String}
       */
      this.$emit('input', val)
    },
    value() {
      this.model = this.value
    }
  }
}
</script>
